import React from 'react'
import { graphql } from 'gatsby'
import { BlogIndex } from '../components'
 
const BlogTagsTemplate = ({ data, location, pageContext }) => {
  return (
    <BlogIndex 
      location={location} 
      data={data}
      topic={pageContext.topic}
    />
  )
}

export const pageQuery = graphql`
  query BlogTags($topic: String!) {
    posts: allMarkdownRemark(
      filter: {
        frontmatter: {templateKey: {eq: "blog"},
        tags: {eq: $topic}}
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            tags
            author {
              frontmatter {
                authorName
                email
                linkedin
                twitter
              }
            }
            date(formatString: "MMMM DD, YYYY")
            relativeDate: date(fromNow: true)
            featuredpost
            featuredimage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          headings(depth: h2) {
            value
          }
          html
          fields {
            slug
          }
        }
      }
    }
    tags: allMarkdownRemark(
      filter: {
        frontmatter: {templateKey: {eq: "blog"}}
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            tags
          }
        }
      }
    }
  }
`

export default BlogTagsTemplate
